@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bg-menu {
  background-color: #037c6c;
}
.bg-text {
  color: #fff !important;
}
#bg-text {
  color: #fff !important;
}
.user-drop {
  position: absolute;
  left: -120% !important;
  top: 110% !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.viewall {
  font-size: 13px !important;
  color: #037c6c;
  float: right;
  cursor: pointer;
}
.modal-header .btn-close {
  margin-top: -85px !important;
  margin-right: -16px !important;
  background-color: gray;
  color: red !important;
}
.bg-text {
  color: rgb(216, 216, 216) !important;
}
.tb-purchase {
  overflow-y: auto;
  height: 240px;
  width: 100%;
}
.tb-sales-bill {
  overflow-y: auto;
  height: 375px;
  width: 100%;
}
::-webkit-scrollbar {
  width: 0px;
}
.medi-td {
  width: 22% !important;
}
.login {
  background-color: #037c6c;
  height: 100vh !important;
}
.login-form {
  position: absolute;
  top: 22%;
  left: 35%;
  width: 30%;
  border-radius: 8px;
}
.login-medi {
  width: 100%;
  background-color: #014b42;
  border-radius: 8px;
  padding: 5px 20px;
}
.bg-login {
  background-color: rgba(223, 255, 251, 0.6) !important;
  border-color: rgba(223, 255, 251, 0.6) !important;
}
.doct-cust {
  width: 250px !important;
}
.react-datepicker__input-container input {
  background: none;
  color: white;
}
.cust-det .input-group-text {
  background: none !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.cust-det .form-control:focus {
  box-shadow: none !important;
  border-color: #037c6c !important;
}
.bg-custde {
  background-color: #edfffd !important;
}
.bg-auto {
  background-color: #e9ecef;
  position: absolute;
  width: 600px;
  border-radius: 8px;
}
.medi-item {
  padding: 8px 0 !important;
  border-radius: 8px;
}
.medi-item:hover {
  background-color: #bcbcbc;
  cursor: pointer;
}
.item-stock {
  font-size: 10px !important;
  color: #555555;
}
.form-control:disabled {
  background: none !important;
  border: none !important;
}
.bg-history {
  height: 79vh !important;
}
.medi-points {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin-top: -15px;
}
